import { FlexBox, Modal, TextField, theme } from '@rario/shared-components'
import BuyBackCardInfo from 'components/BuyBackCardInfo'
import { BuyBackCardWrapper } from 'components/BuyBackCardInfo/BuyBackWidget.styles'
import { Lineseparator } from 'components/FaqSection/Faq.styles'
import { buybackAmountConfig, Scarcity } from 'constant'
import { AppConfigContext } from 'contexts/AppConfigContext'
import useCurrencyConversion from 'hooks/useCurrencyConversion'
import React, { Dispatch, Fragment, SetStateAction, useContext } from 'react'

const BuyBackModal = ({
  isBuyBackModalOpen,
  setIsBuyBackModalOpen,
}: {
  isBuyBackModalOpen?: boolean
  setIsBuyBackModalOpen: Dispatch<SetStateAction<boolean | undefined>>
}) => {
  const { USDToINRExchangeRate } = useContext(AppConfigContext)
  return (
    <Modal
      visible={isBuyBackModalOpen}
      variant="fullScreen"
      modalPosition={'flex-end'}
      onClick={() => setIsBuyBackModalOpen(false)}
      background="radial-gradient(90.45% 119.66% at 118.8% -4.27%, rgba(205, 65, 58, 0.50) 0%, rgba(205, 65, 58, 0.00) 100%), rgba(255, 255, 255, 0.05)"
      backdropFilter={20}
      desktopRight="5%"
      desktopTop="5%"
      top="5%"
      right="5%"
    >
      <FlexBox
        flexDirection={'column'}
        width={'100%'}
        justifyContent="center"
        padding="12px 18px 25px 18px"
      >
        <TextField
          fontFamily={theme.fonts.primary}
          fontWeight="600"
          fontSize={'20px'}
          lineHeight={'24px'}
          alignSelf="center"
          mb="26px"
        >
          Rario BuyBack
        </TextField>
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={'400'}
          fontSize={'12px'}
          lineHeight={'18px'}
          color={theme.colors.whites[9]}
          whiteSpace="pre-line"
        >
          Rario BuyBack is here! you can burn your holdings and receive value based on below
          mentioned offering. {'\n\n'} Conversion value: $1 = {USDToINRExchangeRate}
          {'\n\n'}
        </TextField>

        <BuyBackCardWrapper
          flexDirection="column"
          padding="16px"
          borderRadius="6px"
          border="1px solid #2e2e2f"
          background="rgba(255, 255, 255, 0.04)"
        >
          {Object.values(Scarcity).map((tier, i) => {
            const [formattedSalePriceINR] = useCurrencyConversion(buybackAmountConfig.card[tier])
            return (
              <Fragment key={tier}>
                <BuyBackCardInfo
                  name={tier + ' Cards'}
                  priceInUSD={buybackAmountConfig.card[tier]}
                  priceInINR={formattedSalePriceINR}
                  tier={tier}
                />
                {Object.values(Scarcity).length !== i + 1 && (
                  <FlexBox my="14px">
                    <Lineseparator />
                  </FlexBox>
                )}
              </Fragment>
            )
          })}
        </BuyBackCardWrapper>

        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={'400'}
          fontSize={'12px'}
          lineHeight={'18px'}
          color={theme.colors.whites[9]}
          whiteSpace="pre-line"
        >
          {'\n\n'}All your holdings including cards, Fan Badges & Moments will be burned once you
          opt in for the Rario BuyBack.
        </TextField>
      </FlexBox>
    </Modal>
  )
}

export default BuyBackModal
