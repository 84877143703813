import { doDelete, doGet, doPatch, doPost } from 'api'
import { D3XPAPIParam, NFTSaleListingAPIParam, NFTSaleListingAPIResponse } from 'interfaces'
import { getHeaders } from 'utils/utils'
import API_CONSTANTS from './constants'

export const getNFTSaleListing = (
  accessToken: string,
  id: string,
  params: NFTSaleListingAPIParam = {}
): Promise<NFTSaleListingAPIResponse> => {
  const { currentPage = 0, limit = 10, sortBy = 'price', sortDir = 'asc' } = params
  return doGet(
    API_CONSTANTS.GET_NFT_SALE_LISTING,
    { id, currentPage, limit, sortBy, sortDir },
    { headers: getHeaders(accessToken) }
  )
}

export const getNFTListingById = (accessToken: string, listingId?: string) => {
  return doGet(
    API_CONSTANTS.GET_NFT_LISTING_BY_ID,
    { listingId },
    { headers: getHeaders(accessToken) }
  )
}

export const createNFTListing = (
  accessToken: string,
  price: string,
  currency: string,
  tokenId?: string | string[]
) => {
  return doPost(
    API_CONSTANTS.POST_NFT_TOKEN_LISTING,
    {},
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        tokenId,
        price,
        currency,
      }),
    }
  )
}

export const updateNFTListing = (accessToken: string, listingId: string, updatedPrice: string) => {
  return doPatch(
    API_CONSTANTS.PATCH_NFT_TOKEN_LISTING_PRICE,
    { listingId },
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        price: updatedPrice,
      }),
    }
  )
}

export const cancelNFTListing = (accessToken: string, listingId: string) => {
  return doDelete(
    API_CONSTANTS.DELETE_NFT_LISTING,
    { listingId },
    { headers: getHeaders(accessToken) }
  )
}

// Public API
export const getNFTSalesHistory = (catalogId: string, currentPage: Number, tokenId?: string) => {
  return doGet(
    API_CONSTANTS.NFT_SALES_HISTORY_TOKEN,
    { catalogId, page: currentPage, limit: 10, tokenId },
    { headers: getHeaders() }
  )
}

// Public API
export const getD3XPValues = (data: D3XPAPIParam[]) => {
  return doPost(
    API_CONSTANTS.GET_XP_VALUE,
    {},
    {
      headers: getHeaders(),
      body: JSON.stringify({
        tokens: data,
      }),
    }
  )
}

// Public API
export const getD3TagStatus = (data: string[]) => {
  return doPost(
    API_CONSTANTS.GET_NFT_TAG_STATUS,
    {},
    {
      headers: getHeaders(),
      body: JSON.stringify({
        tokenIds: data,
      }),
    }
  )
}

export const burnCardListings = (accessToken: string, campaignId: number, tokenIds: number[]) => {
  return doPost(
    API_CONSTANTS.BURN_CARDS,
    {},
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        campaignId,
        tokenIds,
      }),
    }
  )
}
export const burnCardCampaignConfig = (accessToken: string) => {
  return doGet(
    API_CONSTANTS.BURN_CARDS_CONFIG,
    {},
    {
      headers: getHeaders(accessToken),
    }
  )
}

// Buyback User Cards
export const buybackUserCards = (accessToken: string) => {
  return doPost(
    API_CONSTANTS.BUYBACK_USER_CARDS,
    {},
    {
      headers: getHeaders(accessToken),
    }
  )
}

// Buyback User Cards status
export const buybackUserCardsStatus = (accessToken: string) => {
  return doGet(
    API_CONSTANTS.BUYBACK_USER_CARDS,
    {},
    {
      headers: getHeaders(accessToken),
    }
  )
}
