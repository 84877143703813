import { FB_PIXEL_ID } from 'config'
import { CatalogTypeEnum, ScarcityEnum, ScarcityProps } from 'interfaces'
import { PhoneVerificationText } from 'interfaces/phoneVerification'
import { PicksTimerTextType } from 'interfaces/picks'
import { cardPriceObjectType } from 'interfaces/sellOnMarket'
import { getCDNUrl } from 'utils/utils'

export const KYC_MAX_AMOUNT_LIMIT = 3000

export const API_ERRORS = {
  USER_CONFLICT_ERROR: 'UserConflictError',
  USER_PHONE_OTP_LIMIT_EXCEEDED: 'UserPhoneOtpLimitExceeded',
  USER_EMAIL_OTP_LIMIT_EXCEEDED: 'UserEmailOtpLimitExceeded',
  USER_BLACKLIST_ERROR: 'UserBlacklistError',
  USER_EMAIL_ALREADY_VERIFIED: 'EmailAlreadyVerifiedError',
  UNAUTHORIZED: 'Unauthorized',
}

export const OTP_TIMER_COUNT = 30
export const PHONE_VERIFY_PROHIBITED_COUNTRY: string[] = ['+93']
export const BACKGROUND_TYPES: { [key: string]: { background: string; boxShadow: string } } = {
  gold: {
    background: 'radial-gradient(76.09% 44.76% at 50% 50%, #C18000 0%, #5A2C01 100%)',
    boxShadow: 'inset 10.1249px 10.1249px 17.7186px rgba(255, 255, 255, 0.05)',
  },
  silver: {
    background: 'radial-gradient(76.09% 44.76% at 50% 50%, #B7B7B7 0%, #515151 100%)',
    boxShadow: 'inset 10.1249px 10.1249px 17.7186px rgba(255, 255, 255, 0.05)',
  },
  unique: {
    background: 'radial-gradient(76.09% 44.76% at 50% 50%, #C10000 0%, #5A0101 100%)',
    boxShadow: 'inset 10.1249px 10.1249px 17.7186px rgba(255, 255, 255, 0.05)',
  },
  black: {
    background: 'radial-gradient(76.09% 44.76% at 50% 50%, #303030 0%, #0B0B0B 100%)',
    boxShadow: 'inset 10.1249px 10.1249px 17.7186px rgba(255, 255, 255, 0.05)',
  },
}

// CENTRALIZED SEO MAPING FOR ALL PAGES
export const SEO_MAP: { [key: string]: any } = {
  RARIO: {
    '/': {
      title: 'RARIO | Officially Licensed Cricket Digital Player Cards Platform',
      description:
        "World's first officially licensed digital collectibles platform for cricket enables fans to buy, sell & trade cricket Digital Player Cards of their favorite cricket players and cricket leagues!",
      Keywords:
        'Cricket Digital Player Card, Cricket Digital Player Cards, Cricket Digital Player Card Marketplace, Digital Collectibles Platform, Australian Cricket Digital Player Cards, Cricket player Digital Player Card, Team Digital Player Cards, Cricket Digital Player Card Collections',
    },
    '/packs': {
      title: 'Cricket Digital Player Card Pack Collection - Rario Packs',
      description:
        'Check out the the latest Cricket Digital Player Card Packs of iconic on-field moments and build your collection',
      Keywords:
        'Digital Player Card Packs, Cricket Digital Player Card, Digital Player Card Cards, Digital Player Card Collectibles, Cricket Digital Player Card Collections, Digital Player Cards, Cricket Digital Player Card Marketplace, Cricket Digital Player Card Platform, Cricket Digital Player Card Packs, Rario Marketplace',
    },
    '/marketplace': {
      title: 'Cricket Digital Player Card Marketplace - Player Cards and Moments',
      description:
        'Your favourite players and most memorable moments - buy and sell officially licensed Cricket Digital Player Cards in the Rario Marketplace.',
      Keywords:
        'Cricket Digital Player Cards, Cricket Digital Player Card Marketplace, Cricket Digital Player Card Platform, Cricket Digital Player Card Cards, Cricket Digital Player Card Collectibles, Buy Cricket Digital Player Cards, Sell Cricket Digital Player Cards, Rario, Cricket Moment Digital Player Cards, Player Digital Player Cards',
    },
    '/vault': {
      title: 'RARIO | Vault',
      description:
        "World's first officially licensed digital collectibles platform for cricket enables fans to buy, sell & trade cricket Digital Player Cards of their favorite cricket players and cricket leagues!",
      Keywords:
        'Cricket Digital Player Card, Cricket Digital Player Cards, Cricket Digital Player Card Marketplace, Digital Collectibles Platform, Australian Cricket Digital Player Cards, Cricket player Digital Player Card, Team Digital Player Cards, Cricket Digital Player Card Collections',
    },
  },
  CR: {
    '/': {
      title:
        'Australian Cricket Digital Player Cards, Player Cards - Cricket Digital Player Card Marketplace | CRICRUSH',
      description:
        'Cricrush, an Australia Cricket Digital Player Card Marketplace. Collect, trade, and play with officially licensed Australia cricket Digital Player Cards. We offer Cricket Digital Player Cards and player cards of your favorite Australian cricketers.',
      Keywords:
        'Cricrush, an Australia Cricket Digital Player Card Marketplace. Collect, trade, and play with officially licensed Australia cricket Digital Player Cards. We offer Cricket Digital Player Cards and player cards of your favorite Australian cricketers.',
    },
    '/marketplace': {
      title: 'Cricket Digital Player Card Marketplace - Play with Player Cards',
      description:
        'A Cricket Digital Player Card Marketplace feature has been launched by Cricrush so users can buy, sell, and trade Cricket Digital Player Cards. Cricrush Digital Player Cards include player cards, video moments, and artifacts.',
      Keywords:
        'Cricrush, an Australia Cricket Digital Player Card Marketplace. Collect, trade, and play with officially licensed Australia cricket Digital Player Cards. We offer Cricket Digital Player Cards and player cards of your favorite Australian cricketers.',
    },
    '/packs': {
      title: 'Cricket Digital Player Card Marketplace - Play with Player Cards',
      description:
        'Australian Cricket Digital Player Card Packs. The latest and previous Digital Player Card packs are available here.',
      Keywords:
        'Australian Cricket Digital Player Card Packs. The latest and previous Digital Player Card packs are available here.',
    },
  },
  WEBVIEW_APP: {
    '/': {
      title: 'RARIO | Officially Licensed Cricket Digital Player Cards Platform',
      description:
        "World's first officially licensed digital collectibles platform for cricket enables fans to buy, sell & trade cricket Digital Player Cards of their favorite cricket players and cricket leagues!",
      Keywords:
        'Cricket Digital Player Card, Cricket Digital Player Cards, Cricket Digital Player Card Marketplace, Digital Collectibles Platform, Australian Cricket Digital Player Cards, Cricket player Digital Player Card, Team Digital Player Cards, Cricket Digital Player Card Collections',
    },
    '/packs': {
      title: 'Cricket Digital Player Card Pack Collection - Rario Packs',
      description:
        'Check out the the latest Cricket Digital Player Card Packs of iconic on-field moments and build your collection',
      Keywords:
        'Digital Player Card Packs, Cricket Digital Player Card, Digital Player Card Cards, Digital Player Card Collectibles, Cricket Digital Player Card Collections, Digital Player Cards, Cricket Digital Player Card Marketplace, Cricket Digital Player Card Platform, Cricket Digital Player Card Packs, Rario Marketplace',
    },
    '/marketplace': {
      title: 'Cricket Digital Player Card Marketplace - Player Cards and Moments',
      description:
        'Your favourite players and most memorable moments - buy and sell officially licensed Cricket Digital Player Cards in the Rario Marketplace.',
      Keywords:
        'Cricket Digital Player Cards, Cricket Digital Player Card Marketplace, Cricket Digital Player Card Platform, Cricket Digital Player Card Cards, Cricket Digital Player Card Collectibles, Buy Cricket Digital Player Cards, Sell Cricket Digital Player Cards, Rario, Cricket Moment Digital Player Cards, Player Digital Player Cards',
    },
    '/vault': {
      title: 'RARIO | Vault',
      description:
        "World's first officially licensed digital collectibles platform for cricket enables fans to buy, sell & trade cricket Digital Player Cards of their favorite cricket players and cricket leagues!",
      Keywords:
        'Cricket Digital Player Card, Cricket Digital Player Cards, Cricket Digital Player Card Marketplace, Digital Collectibles Platform, Australian Cricket Digital Player Cards, Cricket player Digital Player Card, Team Digital Player Cards, Cricket Digital Player Card Collections',
    },
  },
}

export const momentMinPrice: cardPriceObjectType = {
  silver: 3,
  gold: 50,
  black: 500,
  bronze: 3,
}
export const cardMinPrice: cardPriceObjectType = {
  silver: 5,
  gold: 25,
  black: 100,
  bronze: 1,
}
export const cardType = ['silver', 'gold', 'black', 'bronze']

export const momentMaxPrice = 2500000
export const defaultCardMaxPrice = 2000000

export const ListAndUpdateButtonText: { [key: string]: string } = {
  true: 'UPDATE LISTING',
  false: 'LIST CARD',
}

export const FreeBy: { [key: string]: string } = {
  RARIO: ' Free by Rario',
  CR: ' Free by CricRush',
  WEBVIEW_APP: ' Free by Rario',
}

export const RefundedBy: { [key: string]: string } = {
  RARIO: 'Refunded by Rario',
  CR: 'Refunded by CricRush',
  WEBVIEW_APP: 'Refunded by Rario',
}

export const REDIRECT_INTERVAL = 1000

export const PHONE_VERIFICATION_MODAL_TEXT: { [key: string]: PhoneVerificationText } = {
  RARIO: {
    statusTextHeading: 'Verify phone',
    statusTextSubheading: 'Just a few details for a more personalised Rario experience.',
  },
  CR: {
    statusTextHeading: 'Please verify your identity',
    statusTextSubheading: 'Please enter the verification code sent to your registered phone number',
  },
  WEBVIEW_APP: {
    statusTextHeading: 'Verify phone',
    statusTextSubheading: 'Just a few details for a more personalised Rario experience.',
  },
}

export const PICKS_HEADING: { [key: string]: PicksTimerTextType } = {
  RARIO: { timerHeading: 'RARIO PICKS', carouselHeading: 'RARIO' },
  CR: { timerHeading: 'CRICRUSH PICKS', carouselHeading: 'CRICRUSH' },
  WEBVIEW_APP: { timerHeading: 'RARIO PICKS', carouselHeading: 'RARIO' },
}

export const regexForName = /^[a-zA-Z]+$/
export const regexForPan = /[^A-Za-z0-9]+/
export const allowOnlyNumbersRegex = /^[0-9]$/
export const allowNumberWithDecimals = /^[0-9]*\.?[0-9]*$/

export const WALLET_HEADING: { [key: string]: string } = {
  RARIO: 'Rario',
  CR: 'CricRush',
  WEBVIEW_APP: 'Rario',
}

export const cardDivisionMaxPrice: { [Key: string]: number } = {
  bronze: 100,
  blue: 100,
  silver: 500,
  gold: 2000,
  black: 10000,
}

export const socialData = [
  {
    icon: getCDNUrl('/App/images/community/discord.png'),
    link: 'https://discord.gg/rario',
    name: 'discord',
    height: '24px',
    width: '28px',
  },
  {
    icon: getCDNUrl('/App/images/community/facebook.png'),
    link: 'https://www.facebook.com/rariohqglobal',
    name: 'facebook',
    height: '32px',
    width: '32px',
  },
  {
    icon: getCDNUrl('/App/images/community/instagram.png'),
    link: 'https://www.instagram.com/rariohq',
    name: 'instagram',
    height: '28px',
    width: '28px',
  },
  {
    icon: getCDNUrl('/App/images/community/linkedin.png'),
    link: 'https://www.linkedin.com/company/rariohq/',
    name: 'linkedin',
    height: '28px',
    width: '28px',
  },
  {
    icon: getCDNUrl('/App/images/community/youtube.png'),
    link: 'https://www.youtube.com/channel/UCqo65RCtVWBvICDMF6COhLA/feature',
    name: 'youtube',
    height: '28px',
    width: '28px',
  },
  {
    icon: getCDNUrl('/App/images/community/twitter.png'),
    link: 'https://www.twitter.com/rariohq',
    name: 'twitter',
    height: '28px',
    width: '28px',
  },
]

export const IN_APP_PURCHASE_COOKIE = {
  URL: 'purchase_redirect_url',
  ORDER_ID: 'purchase_order_id',
  SUGGESTED_AMOUNT: 'purchase_suggested_amount',
}

export const UTM_COOKIES = {
  UTM_SOURCE: 'utm_source',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_MEDIUM: 'utm_medium',
  UTM_REF: 'utm_ref',
}

export const COOKIES = {
  ROUND_DETAIL: 'round_detail_',
}

export const APP_PLATFORM = {
  WEBVIEW_APP: 'webview_app',
  WEBSITE: 'website',
  MOBILE_WEBSITE: 'mobile_website',
}

export const API_HEADER_COOKIES = {
  APP_PLATFORM: 'app-platform',
  PLATFORM: 'platform',
}

export const PHONE_VERIFICATION_SKIP_COOKIE = 'is_phone_verification_skipped'
export const IS_ONBOARDING_VIDEO_WATCHED = 'is_onboarding_video_watched'

export const PHONE_VERIFICATION_SUBHEADING_REFER_EARN =
  'Please verify your phone number to unlock the Referral Program and start earning ₹800 for every friend that makes a purchase!'

export const D3LogoLink =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAfCAYAAACYn/8/AAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQOSURBVHgB1VhJaxRBFH49meAkiFMHD3owPaCCHtSIBz24jHgQNJqI4hpERVFxwRWD4oqiiKBxiSiIids1mwEPHjrq3UnyA9ITwYuHVEQlJBPLem0m1vJmNYfkg57pfvX61devXlW9VwCTFE6mhvBUFoffEId8DQngogQSU8OQ4BJpG84IVAoHGIwHQuClfnAv6M9si0RYLOXAc3kbh+LhOQ74QsAeGH+0pKbAXot4aRnrFQAxmNjwNOIl5axGDnkzTAKE1AdJOg6TEeEydk9eQr28zo8iHyS6ukXTy9dizdoqYdq4ev2m+B/cf/DYsqkTj7C4qTB73kLR388L6qj+QYNmY/oMV/T6SVEMfPmeyckiHpAvZ59NpWI6RS+rNjZv3SUKBZKeIx1HEG92COJXQMBlVfbtqw+MRTW91vYOGBgYALeiAlatXG45gMu2ufMXAecDY7JnTx8F+ibQ9qKFCyz5lu210NrWockkYb9EwGpLmYpzCtNnumPtS5auIHWWLFtBDrN54fwwce36LVK3tJRVIs+QxdwBV310XdtDgUcVT3Z194Cf7LN0WDQKuXDxwjnL250fPsG1G7dsZQFXh4d5AkjiQt+eYwTxJEEyXz0V1RvWwaULddY7+w8esXTlTtyYGuRX0s+UxzXiLDrNUjG9m8mzfhbiOJJ3bt+05Ju31ZLvCcOhlMdj6mM0j+F2Y7a3cXJmw7Mnj6xRwncwdHbX7qBeqcHlOv0QIhS0L6NiPNmX2+PZwgTJUSsR2qnesF6uPg2BjgXn32qnEcfMkDJmwhxKt2KWpaNOXhVI2IxrCqhDfFxl+iasSlNhGSYjumY+YRBl9sfx75lDRV0xcA3fWLWenNwbq9YFK4wCFmEsNsi5H4YcYNNsUn3JL7oOFSo+HSpIxCADp8+eh/fv2i0PU5tSajCYg74WKiJl5+FUjJsej7mFT04TL169KUhfT2tDNnHKmyYpahvPtYabKDTc9FVF2LUhY7lXDFKnTw8nHLljRw8BBWw7fuSwJe/q6rFkqQgkLKFMsJrNlJaCqnPybB2pY2Z1mKunM7503o7XqTN1GbNP0waWlRZpXAqxQVVEwyb6OQ+KC7wydYjEVDv7DhwWhcK0ERAvZ89tb5fp3v6bQ+8UhcLMoYspIjLl4dY+g+cfVArZ0va2oA4xPaU6xCIiX/I4kmrKnN3bsngotk5EQviBSC5X3o2hV/+wISCXLgfxHz8Y5VS9mo5t09tBBSQbTsgi7i5MUIQc2DT0i7doMvyRKWMCJia4PDLZa5JGBMRlgu7JPw/GCVgXyj1hNXYa3BcHLyxg8fAgb8zQxygYY+EheWYooAaKhyd336bhn3pnmEdL+R45stUAWQ9A8bDUkxzqR52ZEX8AOKgAEcGMR2UAAAAASUVORK5CYII='

export const VIDEO_FORMATS = ['mp4', 'webm']

export const WEBVIEW_APP = 'WEBVIEW_APP'

export const BONUS_WIDGET_SKIP_COOKIE = 'is_bonus_widget_skipped'

export const D3_CONTEST_DETAIL_KEY = 'd3_contestDetails'

export const DEBOUNCE_AFTER = 500

export const newUserVideoUrl = 'https://assets.rario.com/videos/Master_finch_2-v2.mp4'
export const noGamePlayVideoUrl = 'https://assets.rario.com/videos/Master_finch_2-v2.mp4'
export const noPurchaseVideoUrl = 'https://assets.rario.com/videos/Master_finch_2-v2.mp4'

export enum OGCardType {
  // eslint-disable-next-line no-unused-vars
  TITAN = 'titan',
  // eslint-disable-next-line no-unused-vars
  VETERANS = 'veterans',
  // eslint-disable-next-line no-unused-vars
  GOAT = 'goat',
}

export const aboutUsContent = [
  {
    index: 1,
    heading:
      'At Rario, we are proud to be the world’s first and largest licenced digital cricket collectible platform, dedicated to bringing the fans closer to the sport like never before.',
  },
  {
    index: 2,
    heading:
      "With billions of fans and followers across the globe, cricket is the second-largest sport in the world - it's a passion that unites people from across the globe. Our team of cricket enthusiasts has created a platform that allows fans to collect, trade, and own unique digital assets that represent their favourite players, teams, and moments in the sport. Rario offers a wide range of cricket-themed collectibles that enable apps and utilities that are sure to delight fans of all ages.",
  },
  {
    index: 3,
    heading:
      'We know that cricket is a sport that has a rich history and culture, and we strive to capture that essence in our collectibles. We work closely with cricket experts and players to ensure that every collectible on our platform is authentic, high-quality, and meaningful.',
  },
  {
    index: 4,
    heading:
      'Owning rario collectibles, such as player cards, unleashes access to a world of unparalleled online gaming and once-in-a-lifetime fan experiences. Immerse yourself in D3, an ingenious cricket strategy game, create your squads with player cards and compete to win incredible rewards ranging from official merchandise to VIP match tickets, the exclusive player meets and real-money outcomes. Carry forward your player cards to other (soon to be launched) games, build journeys with digital renditions of your favourite players - don’t just watch the sport, get players to work for you.',
  },
  {
    index: 5,
    heading:
      "We are committed to building a vibrant and inclusive community of cricket fans and collectors, and we welcome everyone to join us on this exciting journey. Whether you're a die-hard cricket fan or just starting to explore the world of digital player cards, Rario is the perfect platform for you.",
  },
  {
    index: 6,
    heading:
      "As avid followers and gamers ourselves, we can't wait to discover what the future holds, and we're thrilled to have you join us on this exhilarating journey.",
  },

  {
    index: 7,
    heading: 'Join us on Rario and immerse in the magic of cricket today!',
  },
]

export const aboutUsAddressData = [
  {
    index: 1,
    country: 'Singapore',
    heading: 'Digital Collectibles Pte. Ltd.68 Circular Road,',
    address: '#02-01Singapore 049422.',
  },
  {
    index: 2,
    country: 'India',
    heading: 'Rario Digital Private Limited',
    subHeading: '(a wholly owned subsidiary of Digital Collectibles Pte. Ltd.)',
    address: '55, 2nd Floor, Lane-2, Westend Marg, Saidulajab, New Delhi, India 110030',
  },
  {
    index: 3,
    country: 'United Arab Emirates',
    heading: 'Rario Digital FZE',
    subHeading: '(a wholly owned subsidiary of Digital Collectibles Pte. Ltd.)',
    address: 'Dubai World Trade Centre, 434-16 Italiacamp 04 Convention Tower, Dubai, UAE',
  },
  {
    index: 4,
    country: 'Australia',
    heading: 'Rario BT Pty. Ltd.',
    subHeading: '(a subsidiary of Digital Collectibles Pte. Ltd.)',
    address: ' 16 Wallaby Place, Bundall QLD 4217, Australia.',
  },
]

export const aboutUsCarouselImageData = [
  {
    index: 1,
    imageSrc: getCDNUrl('/App/images/about/about-us-ankit_wadhwa-v1.png'),
    name: 'Ankit Wadhwa',
    designation: 'Chief Executive Officer',
  },
  {
    index: 2,
    imageSrc: getCDNUrl('/App/images/about/about-us-sunny_bhanot-v1.png'),
    name: 'Sunny Bhanot',
    designation: 'Chief Technology Officer',
  },
  {
    index: 3,
    imageSrc: getCDNUrl('/App/images/about/about-us-rana-v1.png'),
    name: 'Rana',
    designation: 'Chief Partnerships Officer',
  },
  {
    index: 4,
    imageSrc: getCDNUrl('/App/images/about/about-us-nikhil_jha-v1.png'),
    name: 'Nikhil Jha',
    designation: 'SVP, Business Operations',
  },
  {
    index: 5,
    imageSrc: getCDNUrl('/App/images/about/about-us-jyotsna_sharma-v1.png'),
    name: 'Jyotsna Sharma',
    designation: 'General Counsel',
  },
  {
    index: 6,
    imageSrc: getCDNUrl('/App/images/about/about-us-priyesh_kariya-v1.png'),
    name: 'Priyesh Karia',
    designation: 'Chief Finance Officer',
  },
]

export const fbPixelScript = () => {
  const script = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${FB_PIXEL_ID}');
  fbq('track', 'PageView');
`
  return script
}

export const ReferralInfoData = [
  {
    emoji: '🙋🏻‍♂️🙋🏻‍♀️',
    heading: 'Friends Referred',
  },
  {
    emoji: '🎉',
    heading: 'Rewards Earned',
    rewardMoney: 10,
  },
]

export const currentCardType: { [key: string]: string } = {
  silver: 'silver',
  gold: 'gold',
  black: 'black',
  bronze: 'bronze',
}

export const homepageTickerData = [
  'Win Up to **₹30 Crores** in Indian T20 League',
  'Get **₹800** Signup bonus + up to **₹1600** Practice Contest Reward',
]

export const PreferencesDialogBoxContent = {
  categories: [
    {
      key: 'functional',
      name: 'Functional',
      description: 'To monitor the performance of our site and to enhance your browsing',
      example: 'For example, these tools enable us to introduce and explain new features to you.',
    },
    {
      key: 'marketing',
      name: 'Marketing and Analytics',
      description:
        'To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.',
      example:
        'For example, we collect information about which pages you visit to help us present more relevant information.\nTools : Algolia Insights, CleverTap, Mixpanel (Actions)',
    },
    {
      key: 'essential',
      name: 'Essential',
      description: 'We use browser cookies that are necessary for the site to work as intended.',
      example:
        'For example, we store your website data collection preferences so we can honor them if you return to our site. You can disable these cookies in your browser settings but if you do the site may not work as intended.',
    },
  ],
}

export const SalesWidgetPlaceholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABaCAMAAADTj8UHAAABhlBMVEUAAACvr68dIykdISkdHiytra0rKCmhoKAdIScyLi+mpaSfnp6qp6eempo4MzaurKx1c3ReXV1pZ2dJRUhCPD0dIyYkKiocIigcISgdIiiNjI6VlJUdIicdISccISiVlJRQT1EmIyUcIicgIygcISYgIyMcIigcIic5NDd+foCrqKiHh4qwsK4iJCgdIyodICYXIygdIScdISdVVVVVVVUdIicdISgdIigdISccIigcISeGhol/fH5qaGhKR0l9e3w0Li8cIiiwra0cIiceISgdIig0LS+srKx7d3uxsbF9fX0dIihKSkpEREMxMS8tLCpUVFQ3NzVWVlY7OjlPT05RUVFJSEhNTUxCQUAwLy0rKygzMzFAQD41NDMmJSM9PDslJCFHR0YjIh9QUFBGRkVMTEtDQkEhIB45OTcpKCYqKScoJyUsMDQ9Pj0fHhsfHx0dHBk+P0E5OzwrLC0gJCg3ODkyMzUuLzA0NjclKCodHyBCQ0QwMTIgIiQiIyNMTEodISQpLTFdG9+7AAAAS3RSTlMAbVdrEWdnaP5naG1taGdtaGhnZ2ccBvv0u2to9+WRbWdnSEE1DtmkbWhnZ1oxKicL73BtaGDeyqqHfG5tbW1cWE8u58afcU1NQSt18/i6AAADE0lEQVRYw83Q+VcSURjG8aeyGnNfWEQUBdTc99wtbe/VMlEkc4lE05RMHRdMyP7zLtwQZi6Dc8FTfX+8cz7nfc4AHbcMKysrKy4uLioqcrkcY+Pj5RMTFf39/ZOTD1kvngB4OY3c6vSww8gxKzt9F7lWmA++93/gngIzNWXEXieZyebOgC11yp6Z1N5uAfcM0cG8mULUXKDHLXQ2b65DqrFq8Sjtzc2b7ITqNXhKiSzOme4XNabhbru6OWe+hYjNm8K36WBOph90R4MXZQrlg/WXN2TiuPAKL8h0o3hJJh0OS+FAApde4U2ZDnR4RiYdPvzbuBpo49gvU144rMNBmTiuAh5wHJLpMInvJ/DxrEwcN+SJS3LGA0Atx1syHWvxWUAmjiuA9hvAKzJxXAm0ZsLBtEJZcHkCn4Q1hRbT8of1nenwurbQRlr+dV0clzOvx7zZhbSC12CfvuSEOPava7+dJHFFAl/4Mre1xPL7WCJuFbFogz5D3MDxdqYCm6ygT3jX4dP3mdr6yZoV3y8SuB2oSmGxwAxrSx7zVlJawA6gmuNVg1b8rIDu8VTABoXjeiUTrgVKE/joo2Hb8VY1TwI2kYgLOX4r01H+uCRPfA/AFB19kOmIBlPYSxEpHCF3Clso8k6mCHWmMJ6qUlh1WjluBGuYliXsMtUgDQ9SZNl8EeoD6/4f3ORUJbCqWNIxWmj3q9l22GqO+Q9DJ6k7ZlPJo8WoobVdc63RM+hwl01dM5eqePUYIxQ1ZaNUDwFbhyj26fpi1NwjYlh6lZgJa+9CChcimddOsc/ZiylODzJieHopmtVGye6GAUb3I1K+GKdQXSc0uBRpNT0nihrQKNFwAQwwz82On++LnbOztwEdroY2a5+dlP1v2vYvyVbfhKyYZ2khutTwc6KaLkDEVRDzNLPt35Pts8WDEHuQxOJ2dpxbvlgC8+3x45rFAm6AQW62XfuPRTwAo6yjTrKN9CALroBxlvpuQAJLxHDlP8PlOeM24HHOuAN49SZHO/0a7PSYw+FwuVxFiYrjlbFuXRc7/Bu3F3Wfs+huvgAAAABJRU5ErkJggg=='

export const UserKycAttemptsErrorToastMessage =
  'You have reached the maximum number of attempts for KYC submission. Please try again tomorrow.'

export const PendingKycToastMessage =
  'Your KYC is not completed at this moment. You can continue once KYC has been verified.'
export const DreamAuthResources = ['rario.com']
export const DreamAuthresponseType = 'token'
export enum VerifyEmailResetActionType {
  // eslint-disable-next-line no-unused-vars
  EMAIL = 'EMAIL_VERIFICATION',
  // eslint-disable-next-line no-unused-vars
  RESET_PASSWORD = 'PASSWORD_RESET',
}

export const d3redirectUrl = '/d3/game/app/arena'

export const Scarcity: ScarcityProps = {
  GOLD: 'gold',
  SILVER: 'silver',
  BRONZE: 'bronze',
  BLACK: 'black',
}

export interface buybackAmountConfigType {
  [key: string]: {
    [key: string]: number
  }
}

// Buy back Tier/type wise configuration
export const buybackAmountConfig: buybackAmountConfigType = {
  [CatalogTypeEnum.CARD]: {
    [ScarcityEnum.BLACK]: 60,
    [ScarcityEnum.GOLD]: 15,
    [ScarcityEnum.SILVER]: 2,
    [ScarcityEnum.BRONZE]: 0.4,
  },
  [CatalogTypeEnum.MOMENT]: {
    [ScarcityEnum.BLACK]: 0,
    [ScarcityEnum.GOLD]: 0,
    [ScarcityEnum.SILVER]: 0,
    [ScarcityEnum.BRONZE]: 0,
  },
  [CatalogTypeEnum.FAN_BADGE]: {
    [ScarcityEnum.BLACK]: 0,
    [ScarcityEnum.GOLD]: 0,
    [ScarcityEnum.SILVER]: 0,
    [ScarcityEnum.BRONZE]: 0,
  },
}
